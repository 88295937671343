
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ArbitrageItemBuy } from "@/service/arbitrage-checker/ArbitrageTypes";

@Component({})
export default class ArbitrageItemBuyTd extends Vue {
  @Prop()
  buy?: ArbitrageItemBuy;
}
