
import Vue from "vue";
import Component from "vue-class-component";
import OhlcvStat from "@/components/OhlcvStat.vue";
import { ArbitrageHandler } from "@/service/arbitrage-checker/ArbitrageHandler";
import { ArbitrageItem, ArbitrageItemBuy, ExchangeInfo } from "@/service/arbitrage-checker/ArbitrageTypes";
import { Watch } from "vue-property-decorator";
import ExchangeStatus from "@/components/Arbitrage/ExchangeStatus.vue";
import ArbitrageSide from "@/components/Arbitrage/ArbitrageSide.vue";
import MarginInfo from "@/components/Arbitrage/MarginInfo.vue";
import CurrencyNameInfo from "@/components/Arbitrage/CurrencyNameInfo.vue";
import NetworkInfo from "@/components/Arbitrage/NetworkInfo.vue";
import WithdrawFee from "@/components/Arbitrage/WithdrawFee.vue";
import ArbitrageItemBuyTd from "@/components/Arbitrage/ArbitrageItemBuyTd.vue";

@Component({
  components: {
    ArbitrageItemBuyTd,
    WithdrawFee,
    NetworkInfo,
    CurrencyNameInfo,
    MarginInfo,
    ArbitrageSide,
    ExchangeStatus,
    OhlcvStat,
  },
})
export default class ArbitrageItemView extends Vue {
  arbitrageHandler?: ArbitrageHandler;
  arbitrageItems: Array<ArbitrageItem> = [];
  exchangeInfos: Array<ExchangeInfo> = [];
  showOnlyProfitableItems = false;
  capitalOptions = [1000, 5000];
  usdSymbol = "";
  minimumProfit = 1;
  displayedItemsNumber = 100;

  hasSameCurrencyNameUndefined = new Set();

  timer?: number;
  currentTime = Date.now();

  @Watch("showOnlyProfitableItems")
  onShowOnlyProfitableItems() {
    this.arbitrageHandler?.setShowOnlyProfitableItems(this.usdSymbol, this.showOnlyProfitableItems);
  }

  async created() {
    const params = this.$router.currentRoute.params;
    const usdSymbol = params.currencyCode.toUpperCase() + "/USD";
    this.usdSymbol = usdSymbol;

    this.arbitrageHandler = new ArbitrageHandler();
    await this.arbitrageHandler.start();
    this.arbitrageHandler.joinRoom(usdSymbol);
    this.onShowOnlyProfitableItems();

    this.timer = setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);

    this.arbitrageHandler.items$.subscribe((items) => {
      this.arbitrageItems = items;

      for (const item of items) {
        if (item.hasSameCurrencyName === undefined && !this.hasSameCurrencyNameUndefined.has(item.vueKey)) {
          this.hasSameCurrencyNameUndefined.add(item.vueKey);
          console.log(
            "hasSameCurrencyName is undefined",
            item.currency,
            '"' + item.buyBaseCurrencyName + '"',
            '"' + item.sellBaseCurrencyName + '"',
            item.buyMarketUrl,
            item.sellMarketUrl
          );
        }
      }
    });

    this.arbitrageHandler.exchangeInfos$.subscribe((exchangeInfos) => {
      this.exchangeInfos = exchangeInfos.filter((exchangeInfo) => exchangeInfo.symbols.length > 0);
    });
  }

  itemDateClass(itemDate?: number) {
    if (itemDate === undefined) {
      return;
    }
    const diffSec = (this.currentTime - itemDate) / 1000;
    if (diffSec > 300) {
      return "red lighten-3";
    } else if (diffSec > 60) {
      return "orange lighten-3";
    } else if (diffSec > 30) {
      return "yellow lighten-3";
    }
  }

  openDetails(currencyCode: string) {
    const url = this.getArbitrageDetailsUrl(currencyCode);
    window.open(url, "_blank");
  }

  getArbitrageDetailsUrl(currencyCode: string) {
    let routeData = this.$router.resolve({ name: "arbitrageDetails", params: { currencyCode } });
    return routeData.href;
  }

  get arbitrageItemsDisplayed() {
    return this.arbitrageItems
      .sort((a, b) => {
        const bestProfitA = a.bestArbitrage?.profit ?? 0;
        const bestProfitB = b.bestArbitrage?.profit ?? 0;

        if (bestProfitA >= this.minimumProfit && bestProfitB >= this.minimumProfit) {
          if (a.tier > b.tier) {
            return -1;
          }
          if (b.tier > a.tier) {
            return 1;
          }
          return bestProfitB - bestProfitA;
        }

        if (bestProfitA >= this.minimumProfit) {
          return -1;
        }

        if (bestProfitB >= this.minimumProfit) {
          return 1;
        }

        return 0;
      })
      .slice(0, this.displayedItemsNumber);
  }

  getArbitrageItemBuy(buys: ArbitrageItemBuy[], capital: number) {
    return buys.find((buy) => buy.capital === capital);
  }

  destroyed() {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
    }
    this.arbitrageHandler?.stop();
  }
}
